// src/components/ClueAnswer.js

import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useThemeContext } from '../contexts/ThemeContext';
import { shootOff, checkmarkFadeIn, shake } from "../styles/cluesContainerStyles";
import CheckIcon from '@mui/icons-material/Check'; // Import the Check icon

const ClueAnswer = ({ clue, index, selectedSyllable, setSelectedSyllable }) => {
  const { currentTheme } = useThemeContext();
  const [startAnimation, setStartAnimation] = useState(false);
  const [showCorrectAnswer, setShowCorrectAnswer] = useState(false);

  useEffect(() => {
    if (clue.isSolved) {
      setStartAnimation(true);

      const animationDuration = 300; // Duration of shootOff
      setTimeout(() => {
        setShowCorrectAnswer(true);
      }, animationDuration);
    }
  }, [clue.isSolved]);

  const getSyllableDisplay = (syllable) => (syllable === "" || !syllable ? " " : syllable);

  if (showCorrectAnswer) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          color: "#28a745",
          animation: `${checkmarkFadeIn} 0.2s ease-out forwards`,
          minHeight: "30px",
          paddingLeft: "19px",
        }}
      >
        <Typography variant="subtitle1" sx={{ marginRight: "5px", fontWeight: "bold" }}>
          {clue.answer.toUpperCase()}
        </Typography>

        <CheckIcon
          sx={{
            fontSize: "1.5rem",
            marginLeft: "2px",
            position: "relative",
            top: "-2px",
            opacity: 0,
            animation: `${checkmarkFadeIn} 0.2s ease-out forwards`,
            color: "#39b339",
          }}
        />
      </Box>
    );
  }

  // Render syllable buttons (before the clue is solved)
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        gap: "5px",
        marginTop: "3px",
        paddingLeft: "15px",
        flexWrap: "wrap",
        animation: clue.shake ? `${shake} 0.5s` : "none",
      }}
    >
      {clue.filledSyllables.map((syllable, idx) => (
        <Button
          key={idx}
          variant="text"
          onClick={() => {
            if (selectedSyllable !== `${index}-${idx}`) {
              setSelectedSyllable(`${index}-${idx}`);
            }
          }}
          sx={{
            width: "50px",
            minWidth: "50px",
            maxWidth: "50px",
            height: "25px",
            position: "relative",
            borderRadius: "8px",
            backgroundColor:
              selectedSyllable === `${index}-${idx}` ? `rgba(51, 153, 255, 0.5)` : "transparent",
            color: currentTheme.textColor,
            cursor: selectedSyllable === `${index}-${idx}` ? "default" : "pointer",
            transition: "all 0.05s ease-in-out",
            "&:hover": {
              backgroundColor:
                selectedSyllable === `${index}-${idx}`
                  ? `rgba(51, 153, 255, 0.5)`
                  : `rgba(0, 0, 0, 0.1)`,
            },
            "&:active": {
              backgroundColor:
                selectedSyllable === `${index}-${idx}`
                  ? `rgba(51, 153, 255, 0.5)`
                  : `rgba(0, 0, 0, 0.15)`,
            },
            "&::after": {
              content: '""',
              position: "absolute",
              bottom: "-2px",
              left: 0,
              width: "100%",
              height: "2px",
              backgroundColor: currentTheme.textColor,
              borderRadius: "0px",
              transition: "transform 0.05s ease-in-out",
            },
            animation: startAnimation ? `${shootOff} 0.3s ease-out forwards` : "none",
          }}
        >
          <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
            {getSyllableDisplay(syllable)}
          </Typography>
        </Button>
      ))}
    </Box>
  );
};

export default ClueAnswer;
