// src/utils/gameWonModalUtils.js

// Handle share functionality with clipboard and share API
export const handleShareClick = (puzzleType, puzzleNumber, timer, capitalizeFirstLetter, formatTime) => {
    const sharePuzzleTypeText = puzzleType === 'pack' ? `Pack #${puzzleNumber}` : `${capitalizeFirstLetter(puzzleType)} #${puzzleNumber}`;
    const shareText = `${sharePuzzleTypeText}\n---- --- ----\n⏱️: ${formatTime(timer)}\n---- --- ----\n\nwww.syllacrostic.com`;
  
    navigator.clipboard.writeText(shareText).catch(err => console.error('Failed to copy text: ', err));
    if (navigator.share) {
      navigator.share({ title: 'Syllacrostic', text: shareText }).catch(error => console.error('Error sharing:', error));
    } else {
      alert("Results copied to clipboard! You can now manually share.");
    }
  };
  
  // Handle score submission
  export const handleSubmitScoreClick = (timer, handleGameEnd) => {
    const maxScore = 3000;
    const maxTime = 30 * 60; // 30 minutes in seconds
  
    // Guardrail: Ensure timer is a positive integer
    if (typeof timer !== 'number' || !Number.isInteger(timer) || timer < 0) {
      handleGameEnd(1, 'Daily puzzle solved');
      return; // Exit early if timer is invalid
    }
  
    // Special condition invalid time: if timer is > 0 and < 11, score is 1
    if (timer > 0 && timer < 11) {
      handleGameEnd(1, 'Daily puzzle solved');
      return;
    }
  
    const timeElapsed = timer;
    
    // Calculate the score based on the elapsed time
    let score = Math.floor(maxScore - (timeElapsed * (maxScore / maxTime)));
  
  // Guardrail: Ensure score is between 1 and 2995
  score = score < 1 || score > 2995 ? 1 : score;
  
    const reason = 'Daily puzzle solved';
    handleGameEnd(score, reason);
  };
  
    // Handle navigation logic
    export const handleNavigation = (route, navigate, onClose, shouldReset=false) => {
      if (shouldReset) {
        navigate(route, { state: { reset: true } });
      } else {
        navigate(route);
      }
      onClose();
    };