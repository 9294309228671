import { useState, useEffect } from 'react';

// Custom hook to handle archive puzzle list logic
const useArchivePuzzles = (todayPuzzleNo, todayPuzzleDate, puzzleData, sortOrder, showUnplayedOnly) => {
  const [puzzleList, setPuzzleList] = useState([]);
  const [stats, setStats] = useState({ completed: 0, total: 0, averageTime: '--:--' });

  useEffect(() => {
    if (todayPuzzleNo && todayPuzzleDate) {
      let puzzles = [];
      const today = new Date(todayPuzzleDate);

      // Exclude today's puzzle by starting from 1 and going up to todayPuzzleNo - 1
      const totalPuzzles = todayPuzzleNo - 1;

      // Generate puzzle list
      for (let i = 1; i <= totalPuzzles; i++) {
        const puzzleDate = new Date(today);
        puzzleDate.setDate(puzzleDate.getDate() - (todayPuzzleNo - i));
        const dateString = puzzleDate.toISOString().split('T')[0];
        puzzles.push({
          puzzleNo: i,
          puzzleDate: dateString,
          played: false,
          completionTime: '--:--',
        });
      }

      // Try to retrieve and process the puzzle completion data
      try {
        // Filter for daily puzzles excluding today's puzzle
        const dailyPuzzles = puzzleData
          ? puzzleData.filter(
              (entry) =>
                entry.puzzleType === 'daily' && entry.puzzleNumber !== todayPuzzleNo
            )
          : [];

        // Find the best (lowest) completion time for each puzzle number
        const bestTimes = {};
        dailyPuzzles.forEach((entry) => {
          const { puzzleNumber, completionTime } = entry;
          if (
            !bestTimes[puzzleNumber] ||
            completionTime < bestTimes[puzzleNumber].completionTime
          ) {
            bestTimes[puzzleNumber] = entry;
          }
        });

        // Update the puzzles with completion status and times
        puzzles = puzzles.map((puzzle) => {
          const completedPuzzle = bestTimes[puzzle.puzzleNo];
          if (completedPuzzle) {
            return {
              ...puzzle,
              played: true,
              completionTime: completedPuzzle.completionTime,
            };
          }
          return puzzle;
        });

        // Update stats
        const totalCompleted = Object.keys(bestTimes).length;
        const averageTime =
          totalCompleted > 0
            ? (
                Object.values(bestTimes).reduce(
                  (sum, entry) => sum + entry.completionTime,
                  0
                ) / totalCompleted
              ).toFixed(2)
            : '--:--';

        setStats({
          completed: totalCompleted,
          total: totalPuzzles,
          averageTime,
        });
      } catch (error) {
        console.error('Error processing puzzle completion data:', error);
        // Proceed without crashing
        setStats({
          completed: 0,
          total: totalPuzzles,
          averageTime: '--:--',
        });
      }

      // Sort and filter puzzles
      if (sortOrder === 'newest') {
        puzzles = puzzles.reverse();
      }
      if (showUnplayedOnly) {
        puzzles = puzzles.filter((puzzle) => !puzzle.played);
      }

      setPuzzleList(puzzles);
    }
  }, [todayPuzzleNo, todayPuzzleDate, sortOrder, showUnplayedOnly, puzzleData]);

  return { puzzleList, stats };
};

export default useArchivePuzzles;
