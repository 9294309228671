import { useState, useEffect } from 'react';
import axios from 'axios';

// Custom hook to fetch today's puzzle number and date
const useDailyPuzzleNumber = (shouldFetch) => {
  const [todayPuzzleNo, setTodayPuzzleNo] = useState(null);
  const [todayPuzzleDate, setTodayPuzzleDate] = useState(null);
  const [loading, setLoading] = useState(shouldFetch);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!shouldFetch) return; // Do not fetch if not needed

    const fetchDailyPuzzleNumber = async () => {
      try {
        const response = await axios.get('/api/puzzles/daily-puzzle');
        const puzzleData = response.data;

        setTodayPuzzleNo(puzzleData.puzzle_no);
        setTodayPuzzleDate(puzzleData.puzzle_date);
      } catch (err) {
        console.error("Failed to fetch today's puzzle number:", err);
        setError("Failed to fetch today's puzzle number.");
      } finally {
        setLoading(false);
      }
    };

    fetchDailyPuzzleNumber();
  }, [shouldFetch]);

  return { todayPuzzleNo, todayPuzzleDate, loading, error };
};

export default useDailyPuzzleNumber;
