// src/hooks/useArenaHelper.js
import { useEffect } from 'react';

const useArenaHelper = () => {
  let arenaHelper = null;

  if (typeof window !== 'undefined') {
    arenaHelper = window.ArenaHelper ? window.ArenaHelper.getInstance() : null;
  }

  // Function to check if running inside an iframe
  const isInIframe = () => {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  };

  useEffect(() => {
    if (!arenaHelper) {
      console.warn('[Syllacrostic] ArenaHelper is not available.');
    }
  }, [arenaHelper]);

  // Modify initializeArenaHelper to return a Promise
  const initializeArenaHelper = () => {
    return new Promise((resolve, reject) => {
      if (isInIframe() && arenaHelper) {
        console.log('[Syllacrostic] Initializing ArenaHelper.');
        try {
          // Initialize if necessary
          arenaHelper.initTestingEnvironment();
          resolve(); // Resolve the promise when initialization is complete
        } catch (error) {
          console.error('[Syllacrostic] Error initializing ArenaHelper:', error);
          reject(error);
        }
      } else {
        console.warn('[Syllacrostic] Skipping ArenaHelper initialization.');
        resolve(); // Resolve immediately if not in iframe or arenaHelper is not available
      }
    });
  };

  const handleGameStart = () => {
    if (isInIframe() && arenaHelper) {
      console.log('[Syllacrostic] handleGameStart called.');
      arenaHelper.handleGameStart();
    } else {
      console.warn('[Syllacrostic] Cannot call handleGameStart; ArenaHelper is not available or not in iframe.');
    }
  };

  const handleGameEnd = (score, reason) => {
    if (isInIframe() && arenaHelper) {
      console.log(`[Syllacrostic] handleGameEnd called with score: ${score} and reason: ${reason}`);
      arenaHelper.handleGameEnd(score, reason);
    } else {
      console.warn('[Syllacrostic] Cannot call handleGameEnd; ArenaHelper is not available or not in iframe.');
    }
  };

  const handleMidrollRequest = () => {
    if (isInIframe() && arenaHelper) {
      console.log('[Syllacrostic] handleMidrollRequest called.');
      arenaHelper.handleMidrollRequest();
    } else {
      console.warn('[Syllacrostic] Cannot call handleMidrollRequest; ArenaHelper is not available or not in iframe.');
    }
  };

  const handleEventChange = () => {
    if (isInIframe() && arenaHelper) {
      console.log('[Syllacrostic] handleEventChange called.');
      arenaHelper.handleEventChange();
    } else {
      console.warn('[Syllacrostic] Cannot call handleEventChange; ArenaHelper is not available or not in iframe.');
    }
  };

  return {
    initializeArenaHelper,
    handleGameStart,
    handleGameEnd,
    handleMidrollRequest,
    handleEventChange,
  };
};

export default useArenaHelper;
