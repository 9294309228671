// src/components/SidebarDrawer.js

import React from 'react';
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  Drawer,
} from '@mui/material';
import { useNavigate } from 'react-router-dom'; 
import CloseIcon from '@mui/icons-material/Close';
import TodayIcon from '@mui/icons-material/Today';
import AppsIcon from '@mui/icons-material/Apps';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import HistoryIcon from '@mui/icons-material/History';
import SettingsIcon from '@mui/icons-material/Settings';
import FavoriteIcon from '@mui/icons-material/Favorite';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import InfoIcon from '@mui/icons-material/Info';
import { useThemeContext } from '../contexts/ThemeContext';
import { isInIframe } from '../utils/utilityFunctions';


const SidebarDrawer = ({ isDrawerOpen, onClose, handleOpenModal }) => {
  const { currentTheme } = useThemeContext();
  const navigate = useNavigate();
  

  const handleItemClick = (callback) => {
    callback(); // Execute the passed navigation or modal action
  };

  return (
    <Drawer anchor="left" open={isDrawerOpen} onClose={onClose}>
      <Box
        sx={{
          width: 250,
          backgroundColor: currentTheme.secondaryColor,
          height: '100%',
          position: 'relative',
          cursor: 'pointer',
          overflowY: 'auto', // Ensures scrolling is allowed if content overflows
          overflowX: 'hidden',
          scrollbarWidth: 'thin', // Firefox custom scrollbar width
          scrollbarColor: `${currentTheme.primaryColor} transparent`,
        }}
      >
        {/* Header Container */}
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '10px', }}>
          {/* Logo */}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <img src={`${process.env.PUBLIC_URL}/logo512x512.png`} alt="Logo" style={{ height: 30, marginRight: 8, filter: currentTheme.logoInvertColor }} />
          </Box>

          {/* Close Button */}
          <IconButton onClick={onClose} sx={{ color: currentTheme.textColor }}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider sx={{ borderColor: currentTheme.textColor, margin: 1, width: '95%' }} />
        <List>
          <ListItem
            onClick={() => handleItemClick(() => {
              navigate('/daily', { state: { resetTrigger: Date.now() } }); // Pass reset trigger
              onClose();
            })}
          >
            <ListItemIcon>
              <TodayIcon sx={{ color: currentTheme.textColor }} />
            </ListItemIcon>
            <ListItemText primary="Daily Puzzle" sx={{ color: currentTheme.textColor }}/>
          </ListItem>
          <ListItem
            onClick={() => handleItemClick(() => {
              navigate('/pack', { state: { reset: true } }); // Pass reset flag
              onClose();
            })}
          >
            <ListItemIcon>
              <AppsIcon sx={{ color: currentTheme.textColor }} />
            </ListItemIcon>
            <ListItemText primary="Puzzle Packs" sx={{ color: currentTheme.textColor }}/>
          </ListItem>
          <ListItem
            onClick={() => handleItemClick(() => {
              navigate('/archive', { state: { reset: true } }); // Pass reset flag
              onClose();
            })}
          >
            <ListItemIcon>
              <HistoryIcon sx={{ color: currentTheme.textColor }} />
            </ListItemIcon>
            <ListItemText primary="Archive" sx={{ color: currentTheme.textColor }} />
          </ListItem>
          <Divider sx={{ borderColor: currentTheme.textColor, margin: 1, width: '95%' }} />
          <ListItem onClick={() => handleItemClick(() => handleOpenModal('Settings'))}>
            <ListItemIcon>
              <SettingsIcon sx={{ color: currentTheme.textColor}} />
            </ListItemIcon>
            <ListItemText primary="Settings" sx={{ color: currentTheme.textColor }}/>
          </ListItem>
          <ListItem onClick={() => handleItemClick(() => handleOpenModal('HowToPlay'))}>
            <ListItemIcon>
              <HelpOutlineIcon sx={{ color: currentTheme.textColor }} />
            </ListItemIcon>
            <ListItemText primary="How To Play" sx={{ color: currentTheme.textColor }}/>
          </ListItem>

          {!isInIframe() ? (
            <>
              {/* Support and About List Items */}
              <ListItem onClick={() => handleItemClick(() =>  window.open('https://ko-fi.com/pauleyco', '_blank'))}>
              <ListItemIcon>
                <FavoriteIcon sx={{ color: currentTheme.textColor }} />
              </ListItemIcon>
              <ListItemText primary="Support" sx={{ color: currentTheme.textColor }} />
            </ListItem>
            <ListItem
                onClick={() => handleItemClick(() => {
                  navigate('/blog'); // Navigate to the Privacy Policy page
                  onClose();
                })}
              >
                <ListItemIcon>
                  <LibraryBooksIcon sx={{ color: currentTheme.textColor }} />
                </ListItemIcon>
                <ListItemText primary="Blog" sx={{ color: currentTheme.textColor }} />
              </ListItem>
              <ListItem onClick={() => handleItemClick(() => handleOpenModal('About'))}>
                <ListItemIcon>
                  <InfoIcon sx={{ color: currentTheme.textColor }} />
                </ListItemIcon>
                <ListItemText primary="About" sx={{ color: currentTheme.textColor }} />
              </ListItem>
              <Divider sx={{ borderColor: currentTheme.textColor, margin: 1, width: '95%' }} />

              {/* App Store and Google Play Buttons */}
              <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', mt: 1, mb: 1, gap: "10px" }}>
                <a href="https://apps.apple.com/us/app/syllacrostic/id6449267889" target="_blank" rel="noopener noreferrer" style={{ flex: '0 0 40%' }}>
                  <img
                    src={`${process.env.PUBLIC_URL}/appstore.png`}
                    alt="App Store Logo"
                    style={{ width: '100%', height: 'auto', filter: currentTheme.logoInvertColor }}
                  />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.syllacrostic.app&pli=1" target="_blank" rel="noopener noreferrer" style={{ flex: '0 0 40%' }}>
                  <img
                    src={`${process.env.PUBLIC_URL}/googleplay.png`}
                    alt="Google Play Logo"
                    style={{ width: '100%', height: 'auto', filter: currentTheme.logoInvertColor }}
                  />
                </a>
              </Box>
              <Divider sx={{ borderColor: currentTheme.textColor, margin: 1, width: '95%' }} />

              {/* Privacy Policy and Terms of Service Links */}
              <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', mt: 1, mb: 1, gap: "10px" }}>
              <ListItem
                onClick={() => handleItemClick(() => {
                  navigate('/privacy-policy'); // Navigate to the Privacy Policy page
                  onClose();
                })}
              >
                <ListItemText
                  primary="Privacy Policy"
                  primaryTypographyProps={{
                    sx: { color: currentTheme.textColor, fontSize: '10px', whiteSpace: 'nowrap', textAlign: 'center' } // Apply font size and nowrap
                  }}
                />
              </ListItem>
              <ListItem
                onClick={() => handleItemClick(() => {
                  navigate('/terms-of-service'); // Navigate to the Terms of Service page
                  onClose();
                })}
              >
                <ListItemText
                  primary="Terms of Service"
                  primaryTypographyProps={{
                    sx: { color: currentTheme.textColor, fontSize: '10px', whiteSpace: 'nowrap', textAlign: 'center' } // Apply font size and nowrap
                  }}
                />
              </ListItem>
            </Box>

            <Divider sx={{ borderColor: currentTheme.textColor, margin: 1, width: '95%' }} />
            </>
          ) : (
            <Divider sx={{ borderColor: currentTheme.textColor, margin: 1, width: '95%' }} />
          )}

        </List>
      </Box>
    </Drawer>
  );
};


export default SidebarDrawer;
