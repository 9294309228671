// src/pages/AdminPage.js
import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { AppBar, Box, Tab, Tabs, Toolbar, Typography, Container, Button } from '@mui/material';
import PuzzleQC from '../components/PuzzleQC'; 

// Helper function to create TabPanel for each tab's content
const TabPanel = ({ children, value, index, ...other }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...other}
  >
    {/* Ensure the child is wrapped in a div or Typography with component='div' */}
    {value === index && (
      <Box sx={{ p: 3 }}>
        {/* Use Typography with component='div' to avoid rendering a <p> */}
        <Typography component="div">{children}</Typography>
      </Box>
    )}
  </div>
);

const AdminPage = () => {
  // Manage the selected tab state
  const auth = useAuth();
  const [selectedTab, setSelectedTab] = useState(0);

  // Handle tab change
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      {/* Top Header */}
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Syllacrostic Dashboard
          </Typography>
          <Button
            variant="contained"
            onClick={auth.logout}
            sx={{ width: '80px', fontSize: '12px', color: 'white', backgroundColor: 'black' }}
          >
            Logout
          </Button>
        </Toolbar>
      </AppBar>

      {/* Tabs */}
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        centered
        sx={{ borderBottom: 1, borderColor: 'divider' }}
      >
        <Tab label="Welcome" />
        <Tab label="Analytics" />
        <Tab label="Puzzle QC" />
      </Tabs>

      {/* Tab Content */}
      <Container sx={{ flexGrow: 1 }}>
        <TabPanel value={selectedTab} index={0}>
          {/* Welcome Tab */}
          <Typography variant="h4" component="div">Welcome</Typography>
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          {/* Analytics Tab */}
          <Typography variant="h4" component="div">Analytics</Typography>
        </TabPanel>
        <TabPanel value={selectedTab} index={2}>
          {/* Puzzle QC Tab - Render the PuzzleQC component here */}
          <PuzzleQC />
        </TabPanel>
      </Container>
    </Box>
  );
};

export default AdminPage;
