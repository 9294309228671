// src/components/CustomModal.js

import React from 'react';
import {
  Box,
  IconButton,
  Typography,
  Modal,
  FormControlLabel,
  Switch,
  Fade,
  ListItem,
  ListItemText,
  Divider
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom'; 
import { useThemeContext } from '../contexts/ThemeContext';

import HowToPlay from './HowToPlay'; // HowToPlay Component

/**
 * CustomModal Component
 * - Renders different types of modals (Settings, HowToPlay, About)
 * 
 * @param {boolean} open - Controls modal visibility
 * @param {function} onClose - Function to close the modal
 * @param {string} modalType - The type of modal content to display
 */
const CustomModal = ({ open, onClose, modalType }) => {
  const { currentTheme, setIsDarkMode, isDarkMode } = useThemeContext();
  const navigate = useNavigate();
  

  // Play sound and execute the callback action
  const handleItemClick = (callback) => {
    callback(); // Navigate or perform action
  };

  // Modal container styles
  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    bgcolor: currentTheme.secondaryColor,
    border: `2px solid ${currentTheme.borderColor}`,
    boxShadow: currentTheme.shadow,
    p: 4,
    borderRadius: '8px',
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Fade in={open}>
        <Box sx={modalStyle}>
          
          {/* Close Button */}
          <IconButton
            onClick={onClose}
            sx={{ position: 'absolute', top: 8, right: 8, color: currentTheme.textColor }}
          >
            <CloseIcon />
          </IconButton>

          {/* Render Content Based on Modal Type */}
          {modalType === 'Settings' && (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
              <Typography variant="h6" component="h2" gutterBottom sx={{ color: currentTheme.textColor }}>
                Settings
              </Typography>

              {/* Divider */}
              <Divider sx={{ borderColor: currentTheme.textColor, mt: 1, mb: 1, width: '100%' }} />

              {/* Dark Mode Toggle */}
              <FormControlLabel
                control={
                  <Switch
                    checked={isDarkMode}
                    onChange={(e) => {
                      setIsDarkMode(e.target.checked);
                    }}
                    color="primary"
                  />
                }
                label="Dark Mode"
                sx={{ color: currentTheme.textColor, width: '100%', justifyContent: 'center' }}
              />
            </Box>
          )}

          {/* How To Play Modal Content */}
          {modalType === 'HowToPlay' && (
            <Box sx={{ textAlign: 'center' }}>
              <Typography variant="h6" component="h2" gutterBottom sx={{ color: currentTheme.textColor }}>
                How To Play
              </Typography>
              <Divider sx={{ borderColor: currentTheme.textColor, mt: 1, mb: 1 }} />
              <HowToPlay /> {/* HowToPlay Component */}
            </Box>
          )}

          {/* About Modal Content */}
          {modalType === 'About' && (
            <Box sx={{ textAlign: 'center' }}>
              <Typography variant="h6" component="h2" gutterBottom sx={{ color: currentTheme.textColor }}>
                About
              </Typography>
              <Divider sx={{ borderColor: currentTheme.textColor, mt: 1, mb: 1 }} />

              {/* Developer Information */}
              <Typography variant="body1" sx={{ color: currentTheme.textColor, mb: 2 }}>
                Developed by Mike Pauley<br />
                Tucson, AZ<br />
                <a href="mailto:mike@pauleyco.com" style={{ color: currentTheme.textColor }}>
                  mike@pauleyco.com
                </a>
                <hr />
                Find my other games at:<br />
                <a href="http://pauleyco.com" target="_blank" style={{ color: currentTheme.textColor }}>
                  www.PauleyCo.com
                </a>
                <hr />
                Syllacrostic © 2024 PauleyCo, LLC<br />
                Version: 1.1.1<br />
                <Divider sx={{ borderColor: currentTheme.textColor, mt: 1, mb: 1 }} />
                </Typography>

              {/* Privacy Policy and Terms of Service Links */}
              <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', mt: 1, mb: 1, gap: '10px' }}>
                <ListItem onClick={() => handleItemClick(() => { navigate('/privacy-policy'); onClose(); })}>
                  <ListItemText
                    primary="Privacy Policy"
                    primaryTypographyProps={{
                      sx: { color: currentTheme.textColor, fontSize: '12px', textAlign: 'center' }
                    }}
                  />
                </ListItem>
                <ListItem onClick={() => handleItemClick(() => { navigate('/terms-of-service'); onClose(); })}>
                  <ListItemText
                    primary="Terms of Service"
                    primaryTypographyProps={{
                      sx: { color: currentTheme.textColor, fontSize: '12px', textAlign: 'center' }
                    }}
                  />
                </ListItem>
              </Box>
            </Box>
          )}
        </Box>
      </Fade>
    </Modal>
  );
};

export default CustomModal;
