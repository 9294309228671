// src/components/GlobalStyles.js
import React from 'react';
import { GlobalStyles as MUIGlobalStyles } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useThemeContext } from '../contexts/ThemeContext';

const GlobalStyles = () => {
  const { currentTheme } = useThemeContext();
  const location = useLocation();

  // List of paths where text selection and cursor should be enabled (protected routes)
  const protectedPaths = ['/admin', '/login'];

  // Determine whether to disable text selection and cursor
  const disableSelection = !protectedPaths.includes(location.pathname);
  const disableScrolling = true;

  return (
    <MUIGlobalStyles
      styles={{
        body: {
          userSelect: disableSelection ? 'none' : 'text', // Disable text selection conditionally
          cursor: disableSelection ? 'default' : 'text',  // Set cursor style conditionally
          margin: 0,
          padding: 0,
          backgroundColor: currentTheme.primaryColor,
          color: currentTheme.textColor,
          overflow: disableScrolling ? 'hidden' : 'auto', // Disable scroll conditionally
          touchAction: disableScrolling ? 'none' : 'auto', // Di
        },
        '*': {
          userSelect: disableSelection ? 'none' : 'text',
          cursor: disableSelection ? 'default' : 'text',
        },
        'input, textarea': {
          userSelect: 'text', // Always enable text selection for form inputs
          cursor: 'text',     // Set cursor to text for form inputs
        }
      }}
    />
  );
};

export default GlobalStyles;
