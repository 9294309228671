// src/components/styles/cluesContainerStyles.js

import { styled, keyframes } from "@mui/material/styles";
import { Button } from "@mui/material";

// Define animations
export const pulse = keyframes`
  0%, 100% { 
    transform: scale(1); 
    box-shadow: 0 0 0px rgba(0, 0, 0, 0.2); 
  }
  50% { 
    transform: scale(1.05); 
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.4); 
  }
`;

// New animations
export const shootOff = keyframes`
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(100vw);
    opacity: 0;
  }
`;

export const checkmarkFadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateX(100vw);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
`;

export const shake = keyframes`
  0% { transform: translateX(0); }
  20% { transform: translateX(-5px); }
  40% { transform: translateX(5px); }
  60% { transform: translateX(-5px); }
  80% { transform: translateX(5px); }
  100% { transform: translateX(0); }
`;

// Styled Start Button
export const StartButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#28a745",
  color: "#fff",
  width: "70px",       // Width and height set to the same value for a circle
  height: "70px",
  borderRadius: "50%", // Perfect circle shape
  display: "flex",     // Flexbox to center content
  justifyContent: "center", 
  alignItems: "center",
  boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
  animation: `${pulse} 2s infinite`,
  "& .MuiSvgIcon-root": {
    fontSize: "2.5rem", // Increase the size of the PlayArrowIcon
  },
  "&:hover": {
    backgroundColor: "#218838",
    boxShadow: "0 0 20px rgba(0, 0, 0, 0.5)",
  },
}));

