import React from "react";
import { Box, Typography } from "@mui/material";
import { useThemeContext } from '../contexts/ThemeContext';

// Define animations using MUI's `sx` prop keyframes
const animations = {
  pulse: {
    "@keyframes pulse": {
      "0%": { opacity: 1 },
      "50%": { opacity: 0.5 },
      "100%": { opacity: 1 },
    },
    animation: "pulse 2s infinite ease-in-out",
  },
};

/**
 * Loading Component
 * - Displays a loading screen with a logo and animated loading text.
 * 
 * @param {string} message - Optional message to display next to "Loading"
 */
const Loading = ({ message = "" }) => {
  const { currentTheme } = useThemeContext();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "80vh",
        backgroundColor: currentTheme.primaryColor,
        overflow: "hidden",
      }}
    >
      {/* Logo with pulse animation */}
      <Box
        component="img"
        src={`${process.env.PUBLIC_URL}/logo512x512.png`} // Replace with your actual logo path
        alt="Loading Logo"
        sx={{
          width: 150,
          height: 150,
          objectFit: "contain",
          filter: currentTheme.logoInvertColor, // Apply logo invert color from theme
          marginBottom: "10px",
          ...animations.pulse, // Pulse animation
        }}
      />

      {/* Loading text with optional message */}
      <Typography
        variant="h5"
        sx={{
          fontWeight: "bold",
          color: currentTheme.textColor, // Apply theme text color
          marginTop: "10px",
          ...animations.pulse, // Pulse animation
        }}
      >
        Loading{message && ` ${message}`}...
      </Typography>
    </Box>
  );
};

export default Loading;
