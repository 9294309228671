import { useState, useEffect } from "react";

const useTimer = (isActive, stop = false) => {
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    let timerInterval = null;

    if (isActive && !stop) {
      // Start or continue the timer if active and not stopped
      timerInterval = setInterval(() => {
        setTimer((prevTimer) => prevTimer + 1);
      }, 1000);
    } else if (stop) {
      // If the timer is stopped, clear the interval
      clearInterval(timerInterval);
    }

    // Cleanup function to clear the interval on unmount or when dependencies change
    return () => clearInterval(timerInterval);
  }, [isActive, stop]); // Include `stop` as a dependency

  return [timer, setTimer];
};

export default useTimer;
