import React, { createContext, useContext, useState, useEffect } from 'react';
import { themes } from '../styles/themes';

// Create a context for theme settings
const ThemeContext = createContext();

// Custom hook to use the ThemeContext
export const useThemeContext = () => useContext(ThemeContext);

export const ThemeProvider = ({ children }) => {

  // Initialize dark mode and sound preferences from localStorage, or use defaults
  const [isDarkMode, setIsDarkMode] = useState(() => {
    try {
      const storedDarkMode = localStorage.getItem('isDarkMode');
      return storedDarkMode ? JSON.parse(storedDarkMode) : false; // Default: false
    } catch (error) {
      console.error("Failed to retrieve dark mode preference:", error);
      return false; // Default: false
    }
  });


  // Save dark mode preference in localStorage when it changes
  useEffect(() => {
    try {
      localStorage.setItem('isDarkMode', JSON.stringify(isDarkMode));
    } catch (error) {
      console.error("Failed to save dark mode preference:", error);
    }
  }, [isDarkMode]);


  // Define the current theme colors based on dark mode
  const currentTheme = isDarkMode ? themes.dark : themes.light;

  return (
    <ThemeContext.Provider
      value={{
        isDarkMode,
        setIsDarkMode,
        currentTheme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

