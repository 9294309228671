// src/hooks/useGameState.js

import { useState, useEffect } from "react";

const useGameState = (clueStates) => {
  const [gameStarted, setGameStarted] = useState(false);
  const [gameWon, setGameWon] = useState(false);
  const [selectedSyllable, setSelectedSyllable] = useState(null);

  // Check if all clues are solved whenever clueStates change
  useEffect(() => {
    if (gameStarted && clueStates.length > 0) {
      const allSolved = clueStates.every((clue) => clue.isSolved);
      if (allSolved) {
        setGameWon(true);
        //setGameStarted(false);
      }
    }
  }, [clueStates, gameStarted]);

  return {
    gameStarted,
    setGameStarted,
    gameWon,
    setGameWon,
    selectedSyllable,
    setSelectedSyllable,
  };
};

export default useGameState;
