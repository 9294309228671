// src/components/GameWonModal.js

import React, { useState, useEffect } from "react";
import { Box, Typography, Modal, IconButton, Button } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import ShareIcon from '@mui/icons-material/IosShare';
import HistoryIcon from '@mui/icons-material/History';
import TodayIcon from '@mui/icons-material/Today';
import AppsIcon from '@mui/icons-material/Apps';
import LocalCafeIcon from '@mui/icons-material/LocalCafe';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useNavigate } from "react-router-dom";
import { useThemeContext } from '../contexts/ThemeContext';
import { capitalizeFirstLetter, formatTime, calculateCountdown, getReferrer, isInIframe } from '../utils/utilityFunctions';
import { handleShareClick, handleSubmitScoreClick, handleNavigation } from '../utils/gameWonModalUtils';
import Confetti from 'react-confetti';
import useArenaHelper from '../hooks/useArenaHelper'; 

/**
 * GameWonModal Component
 * - Displays a modal when the game is won, showing time, navigation, and sharing options.
 * 
 * @param {boolean} open - Controls modal visibility
 * @param {function} onClose - Function to close the modal
 * @param {number} timer - The time taken to solve the puzzle
 * @param {number} puzzleNumber - Current puzzle number
 * @param {string} puzzleType - Type of puzzle (daily, pack)
 * @param {function} handlePreviousPuzzle - Function to navigate to previous puzzle
 * @param {function} handleNextPuzzle - Function to navigate to next puzzle
 * @param {boolean} isPrevDisabled - Whether the previous button is disabled
 * @param {boolean} isNextDisabled - Whether the next button is disabled
 * @param {number} prevPuzzleNumber - Previous puzzle number
 * @param {number} nextPuzzleNumber - Next puzzle number
 * @param {boolean} isPrevPuzzleCompleted - Whether the previous puzzle is completed
 * @param {boolean} isNextPuzzleCompleted - Whether the next puzzle is completed
 * @param {boolean} isPuzzleCompleted - Whether the current puzzle is completed
 */
const GameWonModal = ({
  open,
  onClose,
  timer,
  puzzleNumber,
  puzzleType = "daily",
  handlePreviousPuzzle,
  handleNextPuzzle,
  isPrevDisabled,
  isNextDisabled,
  prevPuzzleNumber,
  nextPuzzleNumber,
  isPrevPuzzleCompleted,
  isNextPuzzleCompleted,
  isPuzzleCompleted,
}) => {
  const { currentTheme } = useThemeContext();
  const navigate = useNavigate();
  const [showConfetti, setShowConfetti] = useState(false);
  const [confettiPieces, setConfettiPieces] = useState(200);
  const [countdown, setCountdown] = useState('');
  const { handleGameEnd } = useArenaHelper();
  const buyMeCoffeeUrl = "https://ko-fi.com/pauleyco";

  // Referrer information for specific behavior (e.g., coolmathgames)
  const referrer = getReferrer().toLowerCase();
  const isCoolMathGames = referrer.includes('coolmathgames');

  // Conditions for showing different sections
  const shouldShowShareArea = puzzleType === 'daily' && (!isPuzzleCompleted || !isInIframe());
  const shouldShowCannotSubmitText = puzzleType === 'daily' && !isCoolMathGames && isInIframe() && isPuzzleCompleted;

  // Handle confetti animation and countdown
  useEffect(() => {
    if (open) {
      setShowConfetti(true);
      setConfettiPieces(200);
      setTimeout(() => setConfettiPieces(0), 3000);
    }
    const interval = setInterval(() => setCountdown(calculateCountdown()), 1000);
    return () => clearInterval(interval);
  }, [open]);


  // Common button styles
  const buttonStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 1,
    background: currentTheme.secondaryColor,
    color: currentTheme.textColor,
    width: '100%',
    mb: 1,
    padding: '10px 15px',
    borderRadius: '10px',
    fontWeight: "bold",
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)',
    transition: 'all 0.3s ease-in-out',
    "&:hover": {
      transform: "scale(1.05)",
      boxShadow: '0 6px 12px rgba(0, 0, 0, 0.25)',
    },
  };

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="modal-title" aria-describedby="modal-description" sx={{ overflow: 'hidden' }}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: currentTheme.primaryColor,
          border: `2px solid ${currentTheme.borderColor}`,
          boxShadow: 24,
          p: 4,
          borderRadius: "12px",
          width: "350px",
          maxWidth: "80%",
          textAlign: "center",
          maxHeight: "75%",
          overflow: "auto",
          scrollbarWidth: "thin", // Use thin scrollbar for better UX
          scrollbarColor: `${currentTheme.primaryColor} transparent`, // Custom scrollbar colors
        }}
      >
        {/* Confetti animation on win */}
        {showConfetti && (
          <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, overflow: 'hidden' }}>
            <Confetti numberOfPieces={confettiPieces} width={window.innerWidth} height={window.innerHeight} />
          </Box>
        )}

        {/* Header with Logo and Close Button */}
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
          <img src={`${process.env.PUBLIC_URL}/logo512x512.png`} alt="Logo" style={{ height: 30, filter: currentTheme.logoInvertColor }} />
          <IconButton onClick={onClose} sx={{ color: currentTheme.textColor }}>
            <CloseIcon />
          </IconButton>
        </Box>

        {/* Puzzle Title and Time */}
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", mb: 2, backgroundColor: currentTheme.secondaryColor, borderRadius: '8px' }}>
          <Typography variant="h5" component="h2" sx={{ fontWeight: "bold", color: currentTheme.textColor, mb: 0.5, mt: 0.5 }}>
            {puzzleType === 'pack' ? `Pack Puzzle #${puzzleNumber}` : `${capitalizeFirstLetter(puzzleType)} #${puzzleNumber}`}
          </Typography>
          <Box sx={{ width: '90%', height: '2px', backgroundColor: currentTheme.textColor }} />
          <Typography sx={{ mt: 0.5, mb: 0.5, color: currentTheme.textColor, fontWeight: "bold", fontSize: "1.2em" }}>
            Time: {formatTime(timer)}
          </Typography>
        </Box>

        {/* Share and Next Puzzle Section */}
        {shouldShowShareArea && (
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2, backgroundColor: currentTheme.secondaryColor, borderRadius: '8px' }}>
            <Box sx={{ flex: 1, textAlign: 'center' }}>
              <Typography sx={{ fontWeight: "bold", color: currentTheme.textColor, ml: 1 }}>
                Next Puzzle
              </Typography>
              <Typography sx={{ color: currentTheme.textColor, ml: 1 }}>
                {countdown}
              </Typography>
            </Box>

            {/* Share or Submit Button */}
            <Button
              sx={{ ...buttonStyle, flex: 1, padding: '5px', maxWidth: '45%', margin: 1, backgroundColor: "#FFD700", color: "black", "&:hover": { backgroundColor: "#FFC000" } }}
              onClick={
                !isInIframe() 
                  ? () => handleShareClick(puzzleType, puzzleNumber, timer, capitalizeFirstLetter, formatTime)
                  : () => handleSubmitScoreClick(timer, handleGameEnd)
              }
            >
              <ShareIcon />
              {!isInIframe() ? 'Share' : 'Submit Score'}
            </Button>
          </Box>
        )}

        {/* Cannot Submit Score Text for replays */}
        {shouldShowCannotSubmitText && (
          <>
          <Typography sx={{ mt: 2, mb: 1, fontStyle: 'italic', color: currentTheme.textColor }}>
            Cannot submit score on replays
          </Typography>
          <Typography sx={{ mb: 2, fontStyle: 'italic', color: currentTheme.textColor }}>
            Next Puzzle: {countdown}
          </Typography>
        </>
        )}

        {/* Puzzle Navigation (Prev/Next Puzzle) */}
        {puzzleType !== 'daily' && (
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mb: 2, gap: "10px" }}>
            <Button
              onClick={handlePreviousPuzzle}
              disabled={isPrevDisabled}
              sx={{ ...buttonStyle, flex: "1 1 45%", backgroundColor: isPrevDisabled ? "#d3d3d3" : "#2196f3", "&:hover": { backgroundColor: isPrevDisabled ? "#d3d3d3" : "#1976d2" } }}
            >
              <ArrowBackIcon />
              #{prevPuzzleNumber}
              {isPrevPuzzleCompleted && <CheckCircleIcon sx={{ color: 'green' }} />}
            </Button>
            <Button
              onClick={handleNextPuzzle}
              disabled={isNextDisabled}
              sx={{ ...buttonStyle, flex: "1 1 45%", backgroundColor: isNextDisabled ? "#d3d3d3" : "#2196f3", "&:hover": { backgroundColor: isNextDisabled ? "#d3d3d3" : "#1976d2" } }}
            >
              {isNextPuzzleCompleted && <CheckCircleIcon sx={{ color: 'green' }} />}
              #{nextPuzzleNumber}
              <ArrowForwardIcon />
            </Button>
          </Box>
        )}

        {/* More Puzzles Section */}
        <Typography sx={{ mt: 2, mb: 1, fontWeight: "bold", color: currentTheme.textColor }}>
          More Puzzles
        </Typography>

        {/* Puzzle Navigation Buttons */}
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          {puzzleType === 'daily' && (
            <>
              <Button sx={buttonStyle} onClick={() => handleNavigation("/pack", navigate, onClose)} >
                <AppsIcon />
                Packs
              </Button>
              <Button sx={buttonStyle} onClick={() => handleNavigation("/archive", navigate, onClose)} >
                <HistoryIcon />
                Archive
              </Button>
            </>
          )}
          {puzzleType === 'archive' && (
            <>
              <Button sx={buttonStyle} onClick={() => handleNavigation("/archive", navigate, onClose, true)} >
                <HistoryIcon />
                Archive
              </Button>
              <Button sx={buttonStyle} onClick={() => handleNavigation("/pack", navigate, onClose)} >
                <AppsIcon />
                Packs
              </Button>
              <Button sx={buttonStyle} onClick={() => handleNavigation("/daily", navigate, onClose)} >
                <TodayIcon />
                Daily
              </Button>
            </>
          )}
          {puzzleType === 'pack' && (
            <>
              <Button sx={buttonStyle} onClick={() => handleNavigation("/pack", navigate, onClose, true)} >
                <AppsIcon />
                Packs
              </Button>
              <Button sx={buttonStyle} onClick={() => handleNavigation("/archive", navigate, onClose)} >
                <HistoryIcon />
                Archive
              </Button>
              <Button sx={buttonStyle} onClick={() => handleNavigation("/daily", navigate, onClose)} >
                <TodayIcon />
                Daily
              </Button>
            </>
          )}
        </Box>

        {/* External Links (Buy Me a Coffee and App Links) */}
        {!isInIframe() && (
          <>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#FFD700",
                color: "black",
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 1,
                mt: 2,
                width: '100%',
                "&:hover": { backgroundColor: "#FFC000" },
              }}
              href={buyMeCoffeeUrl}
              target="_blank"
            >
              <LocalCafeIcon sx={{ mr: 1 }} />
              Buy Me a Coffee
            </Button>
            <Typography sx={{ mt: 2, mb: 1, fontWeight: "bold", color: currentTheme.textColor }}>
              Get the App
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', mb: 2, gap: "10px" }}>
              <a href="https://apps.apple.com/us/app/syllacrostic/id6449267889" target="_blank" rel="noopener noreferrer" style={{ flex: '0 0 40%' }}>
                <img
                  src={`${process.env.PUBLIC_URL}/appstore.png`}
                  alt="App Store Logo"
                  style={{ width: '100%', height: 'auto', filter: currentTheme.logoInvertColor }}
                />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.syllacrostic.app&pli=1" target="_blank" rel="noopener noreferrer" style={{ flex: '0 0 40%' }}>
                <img
                  src={`${process.env.PUBLIC_URL}/googleplay.png`}
                  alt="Google Play Logo"
                  style={{ width: '100%', height: 'auto', filter: currentTheme.logoInvertColor }}
                />
              </a>
            </Box>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default GameWonModal;
