
// Define light Theme
const lightTheme = {
  primaryColor: "#e4e4e4",   // body      
  secondaryColor: "#f5f5f5", // header
  textColor: "#000000", 
  borderColor: "#000000",   
  shadow: "0px 4px 8px rgba(0, 0, 0, 0.2)", // Increased blur radius, spread, and opacity
  logoInvertColor: "invert(0)",
  timerBorder: `1px solid #000000`,
  buttonDisabled: "rgba(0, 0, 0, 0.26)",
  buttonDisabledBackground: "#d3d3d3",
};

// Define Dark Theme
const darkTheme = {
  primaryColor: "#222222",   // body            
  secondaryColor: "#444444", // header      
  textColor: "#ffffff",
  borderColor: "#FFFFFF",  
  shadow: "0px 4px 8px rgba(255, 255, 255, 0.2)", // Increased blur radius, spread, and opacity for dark mode
  logoInvertColor: "invert(1)", // User for switching syllacrostic logo from white to black in dark mode
  timerBorder: `1px solid #ffffff`,
  buttonDisabled: "rgba(255, 255, 255, 0.12)",
  buttonDisabledBackground: "#555555",
};


// Export the themes
export const themes = {
  light: lightTheme,
  dark: darkTheme,
};
