import { useEffect, useState } from 'react';

// Custom hook to process and manage pack puzzles data
const usePackPuzzles = (selectedPack, puzzleData) => {
  const [packPuzzles, setPackPuzzles] = useState([]);
  const [stats, setStats] = useState({ completed: 0, total: 0, averageTime: '--:--' });

  useEffect(() => {
    if (selectedPack) {
      // Generate puzzles in the selected pack
      let puzzles = [];
      for (let i = selectedPack.start; i <= selectedPack.end; i++) {
        puzzles.push({
          puzzleNo: i,
          played: false,
          completionTime: '--:--',
        });
      }

      // Try to retrieve and process the puzzle completion data
      try {
        // Filter for pack puzzles of the selected pack
        const packPuzzlesData = puzzleData ? puzzleData.filter((entry) => entry.puzzleType === 'pack') : [];

        // Find the best (lowest) completion time for each puzzle number
        const bestTimes = {};
        packPuzzlesData.forEach((entry) => {
          const { puzzleNumber, completionTime } = entry;
          if (
            puzzleNumber >= selectedPack.start &&
            puzzleNumber <= selectedPack.end &&
            (!bestTimes[puzzleNumber] || completionTime < bestTimes[puzzleNumber].completionTime)
          ) {
            bestTimes[puzzleNumber] = entry;
          }
        });

        // Update the puzzles with completion status and times
        puzzles = puzzles.map((puzzle) => {
          const completedPuzzle = bestTimes[puzzle.puzzleNo];
          if (completedPuzzle) {
            return {
              ...puzzle,
              played: true,
              completionTime: completedPuzzle.completionTime,
            };
          }
          return puzzle;
        });

        // Update stats
        const totalCompleted = puzzles.filter((puzzle) => puzzle.played).length;
        const averageTime =
          totalCompleted > 0
            ? (
                puzzles.reduce(
                  (sum, puzzle) => (puzzle.played ? sum + puzzle.completionTime : sum),
                  0
                ) / totalCompleted
              ).toFixed(2)
            : '--:--';

        setStats({
          completed: totalCompleted,
          total: puzzles.length,
          averageTime,
        });
      } catch (error) {
        console.error('Error processing puzzle completion data:', error);
        // Proceed without crashing
        setStats({
          completed: 0,
          total: puzzles.length,
          averageTime: '--:--',
        });
      }

      setPackPuzzles(puzzles);
    }
  }, [selectedPack, puzzleData]);

  return { packPuzzles, stats };
};

export default usePackPuzzles;
