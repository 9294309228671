import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

/**
 * ProtectedRoute Component
 * - Protects routes that require authentication.
 * - Redirects to the login page if the user is not authenticated.
 *
 * @param {React.ReactNode} children - The child components to render if authenticated.
 */
const ProtectedRoute = ({ children }) => {
  const { token } = useAuth(); // Destructure token directly from auth context

  // Redirect to the login page if the user is not authenticated
  if (!token) {
    return <Navigate to="/login" />;
  }

  // Render child components if authenticated
  return children;
};

export default ProtectedRoute;
