// src/components/Clue.js
import React from "react";
import { Box, Typography } from "@mui/material";
import ClueAnswer from "./ClueAnswer"; // Component for rendering clue answer
import { useThemeContext } from '../contexts/ThemeContext';

/**
 * Clue component renders a single clue along with its answer display.
 * 
 * @param {Object} clue - The clue object containing the clue and answer details.
 * @param {number} index - The index of the current clue.
 * @param {string} selectedSyllable - The currently selected syllable.
 * @param {function} setSelectedSyllable - Function to update the selected syllable.
 * @param {string} fadeIn - The fadeIn animation style to apply.
 * @param {boolean} shake - Boolean indicating whether the shake animation should be applied.
 */
const Clue = ({ clue, index, selectedSyllable, setSelectedSyllable, fadeIn, shake }) => {
  const { currentTheme } = useThemeContext(); // Access current theme colors

  return (
    <Box
      key={index} // Unique key for the current clue
      id={`clue-${index}`} // Unique ID for reference
      sx={{
        marginBottom: "20px",
        animation: `${fadeIn} 0.3s ease-in-out`, // Apply fade-in animation
        position: "relative",
        minHeight: "60px", // Ensure minimum height for consistent clue size
      }}
    >
      {/* Clue text with bold styling */}
      <Typography sx={{ color: currentTheme.textColor, fontWeight: "bold" }}>
        {index + 1}. {clue.clue}
      </Typography>

      {/* Render the ClueAnswer component, passing the necessary props */}
      <ClueAnswer
        clue={clue}
        index={index}
        selectedSyllable={selectedSyllable}
        setSelectedSyllable={setSelectedSyllable}
        shake={shake}
      />
    </Box>
  );
};

export default Clue;
