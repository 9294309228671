// src/components/CluesContainer.js
import React, { useRef, useContext } from "react";
import { Box, Typography } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle'; 
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { StartButton, fadeIn, shake } from "../styles/cluesContainerStyles"; // Custom styles for animation and buttons
import { isPuzzleCompleted, capitalizeFirstLetter } from '../utils/utilityFunctions'; // Utility function for text transformation and data retrieval
import { useThemeContext } from '../contexts/ThemeContext'; // Theme context for color and style management
import Clue from "./Clue"; // Clue component for individual clue rendering
import { PuzzleDataContext } from '../contexts/PuzzleDataContext'; // Context to handle puzzle data
import useAutoScroll from '../hooks/useAutoScroll';

/**
 * CluesContainer Component
 * - Renders the container for puzzle clues and controls game states like timer and start button.
 * 
 * @param {Array} clues - List of clue objects with the puzzle data.
 * @param {string} puzzleType - The type of puzzle (e.g., daily, pack).
 * @param {number} puzzleNumber - The number of the current puzzle.
 * @param {boolean} gameStarted - Whether the game has started.
 * @param {number} timer - The current timer value in seconds.
 * @param {string} selectedSyllable - Currently selected syllable.
 * @param {function} setSelectedSyllable - Function to update the selected syllable.
 * @param {function} onStart - Function to trigger the start of the game.
 */
const CluesContainer = ({ clues, puzzleType, puzzleNumber, gameStarted, timer, selectedSyllable, setSelectedSyllable, onStart }) => {
  const { currentTheme } = useThemeContext(); // Access theme context for styling
  const containerRef = useRef(null); // Reference for scrolling to the selected clue
  const { puzzleData: completedPuzzles } = useContext(PuzzleDataContext); // Access puzzle data from the context

  // Check if the puzzle is completed by comparing current puzzle type and number
  const isCompleted = isPuzzleCompleted(completedPuzzles, puzzleType, puzzleNumber);

  // Use the custom hook to auto-scroll to the selected clue
  useAutoScroll(selectedSyllable, containerRef);

  return (
    <Box 
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: "10px",
        border: `1px solid ${currentTheme.borderColor}`,
        backgroundColor: currentTheme.secondaryColor,
        width: "300px",
        borderRadius: "8px",
        maxHeight: "100vh", // Set to full screen height for mobile responsiveness
        flex: 1,
        position: "relative",
      }}
    >
      {/* Header section with puzzle title and checkmark for completion */}
      <Box 
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          padding: "10px 0",
          position: "sticky", // Stick to the top of the container when scrolling
          top: 0,
          zIndex: 1,
          backgroundColor: currentTheme.secondaryColor,
          boxShadow: currentTheme.shadow, // Apply a shadow effect
          marginBottom: "15px",
          borderRadius: "8px",
        }}
      >
        {/* Display the puzzle title */}
        <Typography
          sx={{
            color: currentTheme.textColor,
            fontWeight: "bold",
            fontSize: "1em",
            textAlign: "left",
            paddingLeft: "10px",
            flex: 1,
            display: "flex",
            alignItems: "center",
          }}
        >
          {puzzleType === 'pack' ? 'Pack Puzzle' : capitalizeFirstLetter(puzzleType)} #{puzzleNumber}
          {/* Display a green checkmark if the puzzle is completed */}
          {isCompleted && (
            <CheckCircleIcon
              sx={{
                color: "#4caf50",
                marginLeft: "8px",
                fontSize: "1.2em",
              }}
            />
          )}
        </Typography>

        {/* Timer display fixed to the right */}
        <Box
          sx={{
            position: "absolute",
            right: "10px",
            minWidth: "60px",
            padding: "5px",
            borderRadius: "4px",
            backgroundColor: gameStarted ? "#28a745" : "#6c757d", // Green when started, gray otherwise
            textAlign: "center",
            color: "#ffffff",
            border: currentTheme.timerBorder,
            fontSize: "0.9em",
            fontWeight: "bold",
            transition: "background-color 0.3s ease",
          }}
        >
          {new Date(timer * 1000).toISOString().substr(14, 5)} {/* Display the timer in MM:SS format */}
        </Box>
      </Box>

      {/* Game content area */}
      {!gameStarted ? (
        // Render a Start button if the game hasn't started yet
      <Box sx={{ flex: 1, display: "flex", justifyContent: "center", alignItems: "center" }}>
        <StartButton onClick={onStart}>
          <PlayArrowIcon sx={{ fontSize: "2rem" }} /> {/* Increase the icon size */}
        </StartButton>
      </Box>
      ) : (
        // Render the clue list if the game has started
        <Box
          ref={containerRef} // Scrollable container reference
          sx={{
            overflowY: "auto",
            overflowX: "hidden",
            flex: 1,
            paddingLeft: "5px",
            scrollbarWidth: "thin", // Use thin scrollbar for better UX
            scrollbarColor: `${currentTheme.primaryColor} transparent`, // Custom scrollbar colors
          }}
        >
          {/* Render each clue using the Clue component */}
          {clues.map((clue, index) => (
            <Clue 
              key={index} 
              clue={clue} 
              index={index} 
              selectedSyllable={selectedSyllable} 
              setSelectedSyllable={setSelectedSyllable} 
              fadeIn={fadeIn} 
              shake={shake} 
            />
          ))}
        </Box>
      )}
    </Box>
  );
};

export default CluesContainer;
