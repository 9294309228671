// src/hooks/useAutoScroll.js
import { useEffect } from "react";

/**
 * Custom hook to auto-scroll to a selected clue within a container.
 * 
 * @param {string} selectedSyllable - The currently selected syllable.
 * @param {object} containerRef - React ref object for the container.
 */
const useAutoScroll = (selectedSyllable, containerRef) => {
  useEffect(() => {
    if (selectedSyllable && containerRef.current) {
      const [clueIndex] = selectedSyllable.split("-").map(Number); // Extract the clue index
      const clueElement = containerRef.current.querySelector(`#clue-${clueIndex}`);

      if (clueElement) {
        const container = containerRef.current;

        const containerRect = container.getBoundingClientRect();
        const clueRect = clueElement.getBoundingClientRect();

        // Check if the clue is fully visible within the container
        const isClueAbove = clueRect.top < containerRect.top;
        const isClueBelow = clueRect.bottom > containerRect.bottom;

        if (isClueAbove) {
          // Clue is above the visible area - scroll up to bring it into view
          const offset = clueRect.top - containerRect.top;
          const newScrollTop = container.scrollTop + offset;
          container.scrollTo({ top: newScrollTop, behavior: 'smooth' });
        } else if (isClueBelow) {
          // Clue is below the visible area - scroll down to bring it into view
          const offset = clueRect.bottom - containerRect.bottom;
          const newScrollTop = container.scrollTop + offset;
          container.scrollTo({ top: newScrollTop, behavior: 'smooth' });
        }
        // If the clue is already fully visible, do nothing
      }
    }
  }, [selectedSyllable, containerRef]);
};

export default useAutoScroll;
