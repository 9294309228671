import React, { createContext, useState, useEffect } from 'react';

export const PuzzleDataContext = createContext();

export const PuzzleDataProvider = ({ children }) => {
  const [puzzleData, setPuzzleData] = useState([]);

  // Helper function to parse time strings into seconds
  const parseTimeToSeconds = (timeString) => {
    const parts = timeString.split(':');
    const minutes = parseInt(parts[0], 10);
    const seconds = parseInt(parts[1], 10);
    return minutes * 60 + seconds;
  };

  // Retrieve data from localStorage when app loads
  useEffect(() => {

    try {
      let newPuzzleData = [];

      // Retrieve existing puzzleData from localStorage
      const storedData = localStorage.getItem('puzzleData');
      if (storedData) {
        newPuzzleData = JSON.parse(storedData);
      }

      // Function to merge new entries and avoid duplicates
      const mergePuzzleData = (existingData, newEntries) => {
        const dataMap = {};

        // Add existing data to the map
        existingData.forEach((entry) => {
          const key = `${entry.puzzleType}-${entry.puzzleNumber}`;
          dataMap[key] = entry;
        });

        // Add new entries, updating if the new completionTime is better
        newEntries.forEach((entry) => {
          const key = `${entry.puzzleType}-${entry.puzzleNumber}`;
          if (
            !dataMap[key] ||
            entry.completionTime < dataMap[key].completionTime
          ) {
            dataMap[key] = entry;
          }
        });

        // Return merged data as an array
        return Object.values(dataMap);
      };

      // Retrieve and process old data from 'completedPuzzles' (daily puzzles)
      const completedPuzzlesData = localStorage.getItem('completedPuzzles');
      if (completedPuzzlesData) {
        const completedPuzzles = JSON.parse(completedPuzzlesData);
        const transformedDailyPuzzles = completedPuzzles.map((entry) => ({
          puzzleType: 'daily',
          puzzleNumber: parseInt(entry.puzzleNumber, 10),
          completionTime: parseTimeToSeconds(entry.completedTime),
          dateCompleted: new Date().toISOString(),
        }));

        // Merge with newPuzzleData
        newPuzzleData = mergePuzzleData(newPuzzleData, transformedDailyPuzzles);

        // Optionally remove the old data (not doing for now until migration to new format is complete)
        // localStorage.removeItem('completedPuzzles');
      }

      // Retrieve and process old data from 'completedRegularPuzzles' (pack puzzles)
      const completedRegularPuzzlesData = localStorage.getItem('completedRegularPuzzles');
      if (completedRegularPuzzlesData) {
        const completedRegularPuzzles = JSON.parse(completedRegularPuzzlesData);
        const transformedPackPuzzles = completedRegularPuzzles.map((entry) => ({
          puzzleType: 'pack',
          puzzleNumber: parseInt(entry.puzzleNumber, 10),
          completionTime: parseTimeToSeconds(entry.completedTime),
          dateCompleted: new Date().toISOString(),
        }));

        // Merge with newPuzzleData
        newPuzzleData = mergePuzzleData(newPuzzleData, transformedPackPuzzles);

        // Optionally remove the old data (not doing for now until migration to new format is complete)
        // localStorage.removeItem('completedRegularPuzzles');
      }

      // Update state and localStorage
      setPuzzleData(newPuzzleData);
      
      try {
        localStorage.setItem('puzzleData', JSON.stringify(newPuzzleData));
      } catch (error) {
        console.error('Error storing puzzle data to localStorage:', error);
      }
    } catch (error) {
      console.error('Error retrieving puzzle data from localStorage:', error);
    }
  }, []);

  // Function to add new puzzle completion data
  const addPuzzleCompletion = (puzzleType, puzzleNumber, completionTime) => {
    const newData = {
      puzzleType,
      puzzleNumber,
      completionTime,
      dateCompleted: new Date().toISOString(),
    };

    setPuzzleData((prevData) => {
      // Merge the new data, ensuring no duplicates
      const updatedData = [...prevData];
      const key = `${puzzleType}-${puzzleNumber}`;
      const existingIndex = updatedData.findIndex(
        (entry) => `${entry.puzzleType}-${entry.puzzleNumber}` === key
      );

      if (existingIndex !== -1) {
        // Update if the new completionTime is better
        if (completionTime < updatedData[existingIndex].completionTime) {
          updatedData[existingIndex] = newData;
        }
      } else {
        updatedData.push(newData);
      }

      // Store updated data in localStorage
      try {
        localStorage.setItem('puzzleData', JSON.stringify(updatedData));
      } catch (error) {
        console.error('Error storing puzzle data to localStorage:', error);
      }

      return updatedData;
    });
  };

  return (
    <PuzzleDataContext.Provider value={{ puzzleData, addPuzzleCompletion }}>
      {children}
    </PuzzleDataContext.Provider>
  );
};
