// src/hooks/usePuzzleData.js

import { useState, useCallback } from "react";
import axios from "axios";

const usePuzzleData = (puzzleType, archivePuzzleNo, packPuzzleNo) => {
  const [puzzleData, setPuzzleData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [availableSyllables, setAvailableSyllables] = useState([]);
  const [clueStates, setClueStates] = useState([]);

  // Function to get the correct puzzle endpoint based on the input parameters
  const getPuzzleEndpoint = () => {
    if (puzzleType === 'pack') {
      return `/api/puzzles/pack-puzzle-number/${packPuzzleNo}`;
    } else if (puzzleType === 'archive') {
      return `/api/puzzles/daily-puzzle-number/${archivePuzzleNo}`;
    } else {
      return `/api/puzzles/daily-puzzle`;
    }
  };

  // Fetch puzzle data from the API
  // Memoize fetchPuzzleData to prevent re-creation on every render
  const fetchPuzzleData = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      const endpoint = getPuzzleEndpoint();
      const response = await axios.get(endpoint);
      setPuzzleData(response.data);

      // Initialize available syllables and clue states
      const allSyllables = response.data.clues
        .flatMap((clue) => clue.syllables)
        .sort();

      const syllableObjects = allSyllables.map((syllable) => ({
        text: syllable,
        disabled: false,
      }));

      setAvailableSyllables(syllableObjects);

      const initialClueStates = response.data.clues.map((clue) => ({
        ...clue,
        filledSyllables: Array(clue.syllable_count).fill(""),
        isSolved: false,
        shake: false,
      }));

      setClueStates(initialClueStates);

    } catch (err) {
      console.error("Failed to fetch puzzle data:", err);
      setError("Failed to load puzzle. Please try again later.");
    } finally {
      setLoading(false);
    }
  }, [puzzleType, archivePuzzleNo, packPuzzleNo]);

  return {
    puzzleData,
    loading,
    error,
    availableSyllables,
    clueStates,
    setClueStates,
    setAvailableSyllables,
    fetchPuzzleData,
  };
};

export default usePuzzleData;
