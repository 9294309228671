import React from 'react';
import PuzzleLayout from '../components/PuzzleLayout';

const DailyPage = () => {

  return (
    <PuzzleLayout/>
  );
};

export default DailyPage;
